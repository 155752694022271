import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Layout, SEO, Container } from "../components"
import ImagesGallery, {
  ImagesGallerySection,
  GalleryItem,
} from "../components/styles/engagements"

const Engagements = ({ location }) => {
  const data = useStaticQuery(graphql`
    query GalleryImages {
      engagement_1: file(relativePath: { eq: "engagement_edited_1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_2: file(relativePath: { eq: "engagement_edited_2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_3: file(relativePath: { eq: "engagement_edited_3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_4: file(relativePath: { eq: "engagement_edited_4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_5: file(relativePath: { eq: "engagement_edited_5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_6: file(relativePath: { eq: "engagement_edited_6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_7: file(relativePath: { eq: "engagement_edited_7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement_8: file(relativePath: { eq: "engagement_edited_8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {
    engagement_1,
    engagement_2,
    engagement_3,
    engagement_4,
    engagement_5,
    engagement_6,
    engagement_7,
    engagement_8,
  } = data

  return (
    <Layout
      location={location}
      title="Moments of Doing What We Do Best"
      subTitle="Engagements"
    >
      <SEO
        title="Engagements"
        description="Lightville's activities and events captured in pictures."
      />
      <Container>
        <ImagesGallery>
          <ImagesGallerySection>
            <GalleryItem
              fluid={engagement_1.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
            <GalleryItem
              fluid={engagement_2.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
            <GalleryItem
              fluid={engagement_3.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
          </ImagesGallerySection>

          <ImagesGallerySection>
            <GalleryItem
              fluid={engagement_4.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
            <GalleryItem
              fluid={engagement_5.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
          </ImagesGallerySection>

          <ImagesGallerySection>
            <GalleryItem
              fluid={engagement_6.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
            <GalleryItem
              fluid={engagement_7.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
            <GalleryItem
              fluid={engagement_8.childImageSharp.fluid}
              alt="Taiwo's Presentation"
            />
          </ImagesGallerySection>
        </ImagesGallery>
      </Container>
    </Layout>
  )
}

export default Engagements
