import styled from "@emotion/styled"
import Img from "gatsby-image"

const ImagesGallery = styled.section`
  display: flex;
  margin-bottom: 2em;
  flex-wrap: wrap;
`

export const ImagesGallerySection = styled.article`
  flex: 1 300px;

  @media (min-width: 759px) {
    margin-right: 5px;
  }
`

export const GalleryItem = styled(Img)`
  margin-bottom: 0.5em;
`

export default ImagesGallery
